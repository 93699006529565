import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import Router from 'vue-router'

Vue.use(Router)

const routerOptions = [
  {
    path: '/signin',
    name: 'SignIn',
    component: 'SignIn',
  },
  {
    path: '/',
    component: 'LiveTV',
    name: 'root',
    meta: { requiresAuth: true },
  },
  {
    path: '/dashboard',
    component: 'Dashboard',
    name: 'Dashboard',
    meta: { requiresAuth: true },
  },
  {
    path: '/livetv',
    component: 'LiveTV',
    name: 'LiveTV',
    meta: { requiresAuth: true },
  },
  {
    path: '/specialLive',
    component: 'SpecialLive',
    name: 'SpecialLive',
    meta: { requiresAuth: true },
  },
  {
    path: '/specialVideo',
    component: 'SpecialVideo',
    name: 'SpecialVideo',
    meta: { requiresAuth: true },
  },
]

const routes = routerOptions.map(route => {
  return {
    path: route.path,
    name: route.name,
    component: () => import(`@/views/Pages/${route.component}.vue`),
    meta: route.meta,
  }
})

const router = new Router({
  mode: 'history',
  routes: [
    ...routes,
  ],
})
router.beforeEach((to, from, next) => {
  const requiresAuth = to
    .matched
    .some(record => record.meta.requiresAuth)
  const user = firebase
    .auth()
    .currentUser
  if (requiresAuth && !user) {
    next('/signin')
  }
  next()
})
export default router
