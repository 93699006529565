import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import App from './App.vue'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import { firebaseConfig } from '@/config'

Vue.config.productionTip = false
firebase.initializeApp(firebaseConfig)


const unsubscribe = firebase
  .auth()
  .onAuthStateChanged((firebaseUser) => {
    new Vue({
      vuetify,
      router,
      store,
      render: h => h(App),
      created() {
        store.dispatch('autoSignIn', firebaseUser)
      },
    }).$mount('#app')
    unsubscribe()
  })

