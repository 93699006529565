import firebase from 'firebase/app'
import 'firebase/auth'
import router from '@/router'

export const actions = {
  autoSignIn({ commit }, payload) {
    commit('setUser', payload)
  },
  userCodeRequest({ commit }, payload) {
    commit('setLoading', true)
    firebase.auth().signInWithPhoneNumber(payload.phoneNumber, payload.appVerifier).then(confirmationResult => {
      commit('setConfirmationResult', confirmationResult)
      commit('setLoading', false)
    }).catch(err => {
      console.error(err)
      commit('setError', true)
      commit('setLoading', false)
    })
  },
  userCodeSubmit({ commit, state }, payload) {
    commit('setLoading', true)
    state.confirmationResult.confirm(payload.otpCode).then(result => {
      commit('setUser', result.user)
      commit('setLoading', false)
      router.push({ name: 'LiveTV' })

    }).catch(err => {
      console.error(err)
      commit('setError', true)
      commit('setLoading', false)
    })
  },
  userSignOut({ commit }) {
    firebase.auth().signOut()
    commit('setUser', null)
    router.push('/signin')
  },
}
