export const mutations = {
  setConfirmationResult(state, payload) {
    state.confirmationResult = payload
  },
  setUser(state, payload) {
    state.user = payload
  },
  setError(state, payload) {
    state.error = payload
  },
  setLoading(state, payload) {
    state.loading = payload
  }
}
